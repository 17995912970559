<template>
  <div class="md-layout order">
    <div
      v-if="order && order.id"
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header
          data-background-color="green"
          class="card-header card-header-avatar"
        >
          <div class="order-header">
            <div class="order-info-header">
              <div class="order-info-id">Заказ №: {{ order.id }}</div>
              <div class="order-info-date">
                Дата заказа: {{ getDate(order.created_at) }}
              </div>
              <div class="order-info-status">
                Статус заказа: {{ getStatus(getOrderStatus(order.status)) }}
              </div>
            </div>
          </div>
        </md-card-header>
        <ValidationObserver ref="editOrderForm" :slim="true">
          <md-card-content class="order-info md-layout">
            <md-field>
              <label>Тип доставки</label>
              <md-input v-model="order.delivery_type" :disabled="true" />
            </md-field>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-datepicker
                v-model="order.delivery_date"
                :md-model-type="String"
                :class="{ error: errors.length }"
                @md-opened="reset"
              >
                <ValidationError :errors="errors" />
                <label>Ожидаемая дата доставки</label>
              </md-datepicker>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Статус доставки</label>
                <md-select v-model="order.delivery_status" @focus="reset">
                  <md-option
                    v-for="(item, index) in deliveryStatuses"
                    :key="'status_' + index"
                    :value="item"
                    placeholder="Статус доставки"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </ValidationProvider>

            <md-field>
              <label>Тип оплаты</label>
              <md-input v-model="order.payment_type" :disabled="true" />
            </md-field>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Трек-номер</label>
                <md-input
                  v-model.trim="order.track_number"
                  @focus="reset"
                />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Статус заказа</label>
                <md-input
                    v-model.trim="order.payment_status"
                    disabled
                    @focus="reset"
                />
                <md-button
                  class="md-raised md-primary"
                  @click="handleReject(order.id)"
                  :disabled="!(order.delivery_status == 'Отменено' && defaultDelivary !== 'Отменено')"
                >
                  Отменить заказ
                </md-button>
              </md-field>
            </ValidationProvider>

            <ValidationProvider
                v-if="order.delivery_address"
                v-slot="{ errors, reset }"
                mode="passive"
                rules="required"
                :slim="true"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Адрес</label>
                <md-input
                    class="fixed-width-input"
                    v-model.trim="order.delivery_address"
                    disabled
                    @focus="reset"
                />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
                v-if="cdekPoint"
                v-slot="{ errors, reset }"
                mode="passive"
                :slim="true"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Название пункта выдачи</label>
                <md-input
                    v-model.trim="cdekPoint.name"
                    disabled
                    @focus="reset"
                />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
                v-if="cdekPoint"
                v-slot="{ errors, reset }"
                mode="passive"
                :slim="true"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Адрес пункта выдачи</label>
                <md-input
                    class="fixed-width-input"
                    v-model.trim="cdekPoint.address_full"
                    disabled
                    @focus="reset"
                />
              </md-field>
            </ValidationProvider>

            <div
              class="md-layout-item md-large-size-100"
              style="text-align: right"
            >
              <md-button class="md-raised md-primary" @click="startChat">
                Начать чат
              </md-button>
              <md-button class="md-raised md-primary" @click="editOrder">
                Обновить данные
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>

      <OrderProductsTable
        :products="order.order_product"
        :show-remove-buttons="true"
        @remove-product="selectForRemove"
      />
    </div>

    <ModalConfirm
      v-if="showModal"
      text="Удалить товар из заказа?"
      @confirm="deleteProduct"
      @close="showModal = false"
    />
    <ModalReject
      v-if="showModalReject"
      @reject="reasonId => reject(reasonId)"
      @close="showModalReject = false"
    />
  </div>
</template>

<script>
import ModalConfirm from "@/components/Modals/Confirm";
import ModalReject from  "@/components/Modals/Reject.vue";
import OrderProductsTable from "@/components/Tables/OrderProductsTable";

import { mapState, mapActions } from "vuex";

import { formatISODate } from "@/helpers/formatDate";

export default {
  components: {
    ModalConfirm,
    ModalReject,
    OrderProductsTable,
  },

  data() {
    return {
      order: {},
      selectedId: null,
      showModal: false,
      idReject: null,
      showModalReject: false,
      defaultDelivary: "",
    };
  },

  computed: {
    ...mapState({
      deliveryStatuses: (state) => state.orders.deliveryStatuses,
      initOrder: (state) => state.orders.order,
      orderStatuses: (state) => state.orders.orderStatuses,
      cdekPoint: (state) => state.orders.cdekPoint,
    }),
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    await this.init();
    this.$store.commit("SET_SHOW_LOADER", false);
    this.defaultDelivary = this.order.delivery_status;
  },

  methods: {
    ...mapActions("orders", [
      "getOrder",
      "removeProductFromOrder",
      "updateOrder",
      "getCdekPoint",
      "rejectOrder",
    ]),
    handleReject(id) {
      this.idReject = id;
      this.showModalReject = true;
    },
    reject(reasonId) {
      this.rejectOrder({
        id: this.idReject,
        reasonId: reasonId,
      });
      this.showModalReject = false;
    },
    async deleteProduct() {
      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.removeProductFromOrder({
        order_id: this.order.id,
        product_id: this.selectedId,
      });

      if (success) {
        await this.init();
        this.showModal = false;
        this.selectedId = null;
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },
    async editOrder() {
      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.updateOrder(this.order);
      if (success) {
        this.$router.push("/orders");
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    getDate(isoDate) {
      return formatISODate(isoDate);
    },

    getStatus(status) {
      switch (status) {
        case "waiting_for_payment":
          return "Ожидает оплаты";
        case "paid":
        case "payed":
          return "Оплачен";
        case "on_the_way":
          return "В пути";
        case "completed":
          return "Завершен";
        case "canceled":
        case "declined":
          return "Отменен";
        default:
          return "";
      }
    },

    getOrderStatus(status) {
      for (let key in this.orderStatuses) {
        if (this.orderStatuses[key] == status) {
          return key;
        }
      }
    },

    async startChat() {
      try {
        /* eslint-disable-next-line no-undef */
        const res = await axios.get(`/api/admin/refund-chat/init/${this.$route.params.id}`);
        if (res.data) {
          this.$router.push(`/chats/${res.data.id}`);
        }
      } catch (e) {
        console.error(e);
      }
    },

    async init() {
      let success = await this.getOrder(+this.$route.params.id);
      if (success) {
        this.$set(this.order, "id", this.initOrder.id);
        this.$set(this.order, "order_id", this.initOrder.id);
        this.$set(this.order, "created_at", this.initOrder.created_at);
        this.$set(this.order, "payment_status", this.initOrder.payment_status);
        this.$set(
          this.order,
          "status",
          this.orderStatuses[this.initOrder.status]
        );
        this.$set(this.order, "delivery_type", this.initOrder.delivery_type);
        this.$set(this.order, "delivery_date", this.initOrder.delivery_date);
        this.$set(
          this.order,
          "delivery_status",
          this.initOrder.delivery_status
        );
        this.$set(this.order, "payment_type", this.initOrder.payment_type);
        this.$set(
          this.order,
          "track_number",
          this.initOrder.track_number
        );
        this.$set(this.order, "order_product", this.initOrder.order_product);
        if (this.initOrder.delivery_address) {
          this.$set(this.order, "delivery_address", this.initOrder.delivery_address);
        }
        if (this.initOrder.delivery_point_id) {
          this.$set(this.order, "delivery_point_id", this.initOrder.delivery_point_id);
        }
        this.order.delivery_date = this.getDate(this.order.delivery_date).split(".").reverse().join("-")
        if (this.order.delivery_type === "cdek") {
          try {
            await this.getCdekPoint(this.order.delivery_point_id);
          } catch (e) {
            console.error(e);
          }
        }
      }
    },

    selectForRemove(product_id) {
      this.selectedId = product_id;
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-header-avatar {
  display: flex;
  justify-content: space-between;
}

.order-info {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -15px;

  .md-field {
    flex: 0 0 20%;
    margin: 10px 15px;
  }
}

.order-header {
  display: flex;

  .order-info-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.fixed-width-input {
  width: 500px;
  display: flex;
}

</style>
